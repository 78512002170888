$bg-img: url('../content/img/rotate.svg');

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap');

*, *::before, *::after {
    box-sizing: border-box;
}

#multiselector {
    position: fixed;
    background-color: rgb(239 241 255 / 60%);
    border: 1px dashed rgb(160 172 255 / 95%);
    display: block;
    margin: 0;
    padding: 0;
    left: 50%;
    top: 50%;
    // when testing styling:
    // width: 400px;
    // height: 300px;
    // margin: 0 auto;
}

.toolkit {

    position: absolute;
    top: 20px;
    left: 20px;
    height: 50px;
    column-gap: 3px;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    display:flex;
    display-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;   

    .action {

        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        transition: 0.3s all ease;  
        

        img {
            opacity: 0.4;
            width: 20px;
            display: block !important;
            margin: 0 auto;
            
        }

        &:hover {



            background-color: #ebebeb;
            cursor: pointer;

            img {
                opacity: 1;
            }
            
        }
    }

   


}

@keyframes shiftleft {
    0% {
        transform: translateX(0px);
        opacity: 0;

    }
    30% {
        transform: translateX(10px);
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.success {
    transform: translateX(10px);
    left: 170px;
    position: relative;
    top: 18px;
    animation: shiftleft 1.5s ease-out;
    transition: all 0.5s ease-out;
}

.giphyModal {
    height: 500px;
    overflow:auto;
    position: absolute;
    z-index: 10;
    // background-color: red;
    border: 1px solid rgb(227, 227, 227);
    background-color: white;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 13px 0 0 rgb(50 50 93 / 0%), 0 8px 17px -8px rgb(0 0 0 / 30%);
    left: 100px;
    top: 90px;
    
    text-align:center;


    .gifStickerSwitcher {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .option {
            width: 45%;
            border: 1px solid blue;
            border-radius: 2px;
            padding: 5px;
            cursor: pointer;
            // margin: 0 5px;

        }

        .selected {
            background-color: blue;
            color: white;
        }
    } 

    .search {
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;    

        input {
            box-sizing: border-box;
            margin: 0;
            padding: 10px 10px;
            border: 1px solid gray;
            border-radius: 3px;
            font-size: 15px;
            width: 100%;
        }

        .searchButton {
            margin: 0 0 0 5px;
            position: absolute;
            display: block;
            background-color: #0095ff;
            width: 55px;
            border-radius: 4px;
            position: relative;
            cursor: pointer;
            transition: 0.3s all ease;
            opacity: 0.8;

            &:hover {
                // background-color: #0088e8;
                opacity:1;
                // box-shadow: 0 13px 0 0 rgb(50 50 93 / 0%), 0 8px 17px -8px rgb(0 0 0 / 30%);

            }

            img {
                width: 20px;
                filter: brightness(0) invert(1);
                width: 20px;
                filter: brightness(0) invert(1);
                position: relative;
                top: 9px;
                
                // position: absolute;
                // top: 0;
                // bottom: 0;
                // left: 0;
                // right: 0;
                // margin: auto;
            }
        }

        
        
    }

    // little triangle thing from adhiv.com. gave up with overflow css
    // &:before {
    //     content: "";
    //     top: 55px;
    //     left: 17px;
    //     content: "";
    //     display: block;
    //     width: 10px;
    //     height: 10px;
    //     -webkit-transform: rotate(45deg);
    //     transform: rotate(45deg);
    //     transition: all .8s ease;
    //     position: absolute;
    //     background-color: #1e1e1e;
    // }
}   

.block {
    img:hover {
        outline: 1px solid #00a1ff;
    }
}


.textEditor {

    font-family: 'Lato' !important;

    &:hover {
        border: 1px solid #00a1ff !important;
    }

    blockquote {
        // margin: 0; old
        // padding: 15px;
        // background: #eee;
        // border-radius: 5px;

        border-left: 2px
        solid #ddd;
        margin-left: 0;
        margin-right: 0;
        padding-left: 10px;
        color: #aaa;
    }

}

.DynamicTextEditorToolbar {

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: middle;
    gap: 0 5px;
    outline: 1px solid #dcdcdc;
    border-radius: 3px;
    padding: 5px;
    background-color:rgba(255, 255, 255, 0.8);
    
    

    button {
        border: none;
        padding: 0;
        background-color: transparent;
        transition: 0.3s all ease-out;
        width: 25px;
        height: 25px;
        border-radius: 3px;
        line-height: 4px;
        text-align: center;

        &:hover {
            cursor: pointer;
            background-color: #dcdcdc;
        }

        img {
            width: 12px;
            vertical-align: baseline;

            &:hover {
                outline: none !important;
            }
        }
    }

}

.notFocused {
    .dvNrkR {
        display: none !important;
        border: 1px solid #00a1ff !important;
    }

    .hMFlUR {
        display: none !important;
        border: 1px solid #00a1ff !important;
    }
}

// .dvNrkR .rotate {
//     top: -79px !important;
// }

.dvNrkR .rotate i {
    background-size: 100% 100% !important;
    background-image: $bg-img !important;
    top: -56px !important;
}

.hMFlUR .rotate i {
    background-size: 100% 100% !important;
    background-image: $bg-img !important;
    top: -56px !important;
}



.dvNrkR, .hMFlUR {
    border: 1px solid #00a1ff !important;
}

.square {
    border: 1px solid #00a1ff !important;
}

img {
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}


