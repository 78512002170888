@media (max-width: 768px) {
        #root {
            position: absolute;
            // top: -100px;
            top: 0px;
            bottom: -200px;
            left: 0px; right: 0px;
            overflow: auto;
    }
}



.landing {
    margin-left: 20px;

    .accountLinks a {
        display: inline-block;
        width: max-content;
        margin: 0px 5px 0 0;
        color: inherit;
        text-decoration: none;
    }

    .landingDescription {
        width: 450px;

        h4 {
            margin-bottom: 0;
        }
    }

}

.profile {
    margin-left: 20px;

    h1 {
        font-size: 1.5em;
    }

    .pageFormat {
        display: block;
        margin: 5px 0;

    }

    .pageLink {
        display: inline-block;
    }

    .pageDate {
        color: #999;
    }
    
}

.header {
    display: block;
    margin: 0;
    padding: 0;
    vertical-align: text-bottom;

    a {
        text-decoration: none;
        color: inherit;
    }


    h1 {
        display: inline-block;
        margin-bottom: 0;
        font-size: 1.5em;
    }

    h4 {
        display: inline-block;
        margin-bottom: 0;
        color: #444;
        transition: 0.25s all ease-in-out;
        cursor: default;

        &:hover {
            transform: rotateZ(10deg); /* Rotate around the X-axis on hover */
        }

    }

    p {
        display: inline-block;
        position: relative;
        color: #444;
        left: 4px;
        cursor: default;
    }

    button {
        display: block;
    }
}

.accountForm {
    margin-left: 20px;

    input {
        display: block;
        margin: 10px 0px;
        padding: 10px;
        width: 250px; 
    }

    button {
        margin: 5px 0px;
    }

    .formTinyLink {
        display: block;
        font-size: 12px;
        position: relative;
        top: 15px;
    }
}

.generalButton {
    border: none;
    padding: 8px 10px;
    cursor: pointer;
    border-radius: 3px;
    font-weight: 700;
    color: #131313;
    background-color: #e6e6e6;
    transition: 0.1s all ease-out;
    opacity: 0.85;

    &:hover {
        background-color: #dcdcdc;
        opacity: 1;
    }
}

.inputCustom {
    outline: none;
    padding: 7px;
    border: 1px solid #c7c7c7;
    border-radius: 3px;
    margin-right: 5px;
    transition: 0.1s all ease-out;

    &:hover {
        border: 1px solid #a6a6a6;
        opacity: 1;
    }

    &:focus {
        border: 1px solid #00a1ff !important;
    }
}

a, a:hover, a:visited, a:active {
    
}

.signature {
    color: #939393;
    font-size: 13px;
    position: relative;

    a {
        color: inherit;
        text-decoration: underline;
    }
}


@media (max-width: 1100px) {
    .hideOnMobile {
        display: none !important;
    }

    .landingDescription {
        width: 95% !important;

        img {
            width: 100% !important;
        }

    }
}